import { initialHomesState, HomesState } from './homes.state';
import { HomesActions, EnumHomesActions } from './homes.action';
import { Home } from './homes.interface';
import { getUpdatedCollection } from '../store.functions';
import * as moment from 'moment-timezone';
import { Module } from '@library/store/modules/modules.interface';

export function HomesReducers(state = initialHomesState, action: HomesActions): HomesState {
  switch (action.type) {
    case EnumHomesActions.GetHomesSuccess: {
      const homes = [] as Home[];
      // Deep copy of object to prevent memory reference bugs when accessing payload in other reducers
      const data = JSON.parse(JSON.stringify(action.payload));
      data.filter((home) => home.modules && home.modules.length > 0)
      .forEach(homeData => {
        let home = {} as Home;
        const rooms = [];

        home.place = {
          altitude: typeof homeData.altitude !== 'undefined' ? homeData.altitude : 46,
          coordinates: homeData.coordinates ? homeData.coordinates : [2.352222, 48.856613],
          country: homeData.country ? homeData.country : 'FR',
          timezone: homeData.timezone ? homeData.timezone : 'UTC'
        };

        delete homeData.altitude;
        delete homeData.coordinates;
        delete homeData.country;
        delete homeData.timezone;

        home = {
          ...(homeData as any as Home),
          place: home.place,
        };
        if (homeData.rooms) {
          homeData.rooms.map( room => {
            rooms.push(room.id);
          });
          home.rooms = rooms;
        }
        if (homeData.modules) {
          home.modules = homeData.modules.map(mod => mod.id);
        }
        homes.push(home);
      });

      if (action.modules) {
        const modules: Module[] = JSON.parse(JSON.stringify(action.modules));
        modules.forEach(module => {
          if (!module.bridge) {
            homes.push({
              id: module.id,
              name: (module.mode === 'friend') ? module.home_name + ' (' + module.name + ')' : module.name,
              modules: [module.id, ...(module.modules_bridged ?? [])],
              mode: module.mode,
            } as Home);
          }
        });
      }      

      return {
        ...state,
        homes,
      };
    }

    case EnumHomesActions.HomeSelected: {
      const home = state.homes.find(h => h.id === action.payload);
      if (home?.place?.timezone) {
        moment.tz.setDefault(home.place.timezone);
      }
      return {
        ...state,
        currentHomeId: action.payload
      };
    }

    case EnumHomesActions.GetHomesFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    case EnumHomesActions.UpdateHomePlaceSuccess: {

      const updatedHomes = state.homes.map(home => {
        if (home.id === action.payload.home_id) {
          return { ...home, place: {
            ...home.place,
            coordinates: action.payload.updatedHome['location'],
            altitude: action.payload.updatedHome['altitude'],
            country: action.payload.updatedHome['country'],
            timezone: action.payload.updatedHome['timezone'] ? action.payload.updatedHome['timezone'] : 'UTC'
          }};
        }
        return home;
      });

      return {
        ...state,
        homes: updatedHomes
      };
    }

    case EnumHomesActions.UpdateHomePlaceFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    case EnumHomesActions.GetHomeStatusSuccess: {
      return {
        ...state,
        homes: state.homes && state.homes.length > 0 ? state.homes.map(h => {
          if (h.id === action.payload.id) {
            return {
              ...h,
              status_loaded: true,
            }
          }

          return h;
        }) :
        // if getstatus happens first we still need to set the home

        [
          { id: action.payload.id, status_loaded: true} as Home
        ]
      };
    }

    case EnumHomesActions.GetHomeConfigSuccess: {
      return {
        ...state,
        homes: state.homes && state.homes.length > 0 ? state.homes.map(h => {
          if (h.id === action.payload.id) {
            return {
              ...h,
              config_loaded: true,
            }
          }

          return h;
        }) :
        // if getconfig happens first we still need to set the home
        [
          { id: action.payload.id, config_loaded: true} as Home
        ]
      }
    }

    case EnumHomesActions.GetHomeStatusFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    case EnumHomesActions.GetHomeConfigFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    // add users to home
    case EnumHomesActions.GetHomeUsersSuccess: {

      const updatedHomes = state.homes.map(home => {
        if (home.id === action.payload.id) {
          const updatedHome = { ...home };
          updatedHome.users = Object.keys(action.payload.users).map(key => ({
            ...action.payload.users[key], id: key
          }));

          for (const user of updatedHome.users) {
            if (user.mail) {
              user.name = user.mail;
            } else {
              // in case no mail is returned by the gethomeusers request
              user.name = String(user.id);
            }
          }

          // to put logged in user at the start of the array
          for (let i = 0; i < updatedHome.users.length; i++) {
            if (updatedHome.users[i].is_caller) {
              updatedHome.users.unshift(updatedHome.users[i]);
              updatedHome.users.splice(i + 1, 1);
              break;
            }
          }
          return updatedHome;
        }
        return home;
      });

      return {
        ...state,
        homes: updatedHomes
      };
    }

    // remove user from home store
    case EnumHomesActions.RemoveUserFromHomeSuccess: {

      const updatedHomes = state.homes.map(home => {
        if (home.id === action.payload.homeId) {
          const updatedHome = { ...home };
          updatedHome.users = updatedHome.users.filter((user) => user.id !== action.payload.userId);
          return updatedHome;
        }
        return home;
      });

      return {
        ...state,
        homes: updatedHomes
      };
    }

    case EnumHomesActions.UpdateHomeNameSuccess: {
      const homes = state.homes.map(home => {
        if (home.id === action.payload.home_id) {
          home = { ...home };
          home.name = action.payload.name;
        }
        return home;
      });

      return {
        ...state,
        homes
      };
    }

    case EnumHomesActions.GetAdminAccessCodeSuccess: {

      const updatedHomes = getUpdatedCollection(state.homes, {
        [action.payload.homeId]: {
          admin_access_code: action.payload.accessCode
        }});

      return {
        ...state,
        homes: updatedHomes
      };
    }

    case EnumHomesActions.ShowSpinner: {
      return {
        ...state,
        loading: true
      };
    }

    case EnumHomesActions.HideSpinner: {
      return {
        ...state,
        loading: false
      };
    }

    case EnumHomesActions.ShowErrorModal: {
      return {
        ...state,
        errorModalDisplay: true
      };
    }

    case EnumHomesActions.HideErrorModal: {
      return {
        ...state,
        errorModalDisplay: false
      };
    }

    case EnumHomesActions.GetHomeAddressSuccess: {
      const oldPlace = state.homes.find(h => h.id === action.payload.home_id).place;
      const updatedHomes = getUpdatedCollection(state.homes, {
        [action.payload.home_id]: {
          place: { ...oldPlace, address: action.payload.address }
        }
      });
      return {
        ...state,
        homes: updatedHomes
      };
    }

    case EnumHomesActions.GetHomeAddressFailure: {
      return {
        ...state
      };
    }
    case EnumHomesActions.RemoveRoomFromHome: {
      const homes = state.homes.map(home => {
        home.rooms = home.rooms ?
          home.rooms.filter( (room) => {
            return room !== action.payload.room_id;
          }) :
          [];
        return home;
      });

      return {
        ...state,
        homes
      };
    }
    case EnumHomesActions.SetHomeTypeSuccess: {
      if (!action.payload.home) {
          return state;
      }

      const home = state.homes.find(h => h.id === action.payload.home.id);

      if (!home) {
          return state;
      }

      const newHome = {
          ...home,
          ...action.payload.home
      };

      return {
          ...state,
          homes: [...state.homes.filter(h => h.id !== newHome.id), newHome]
      };
    }
    case EnumHomesActions.SetThermModeSuccess: {
      const home = state.homes.find(h => h.id === action.homeId);

      const newHome = {
          ...home,
          therm_mode: action.therm_mode,
          therm_mode_endtime: action.endtime
      };

      return {
          ...state,
          homes: [...state.homes.filter(h => h.id !== newHome.id), newHome]
      };
      
    }
    case EnumHomesActions.SetHeatingModeSuccess: {
      const home = state.homes.find(h => h.id === action.payload.home.id);

      const newHome = {
          ...home,
          temperature_control_mode: action.payload.home.temperature_control_mode,
          therm_mode: action.payload.home.therm_mode,
          therm_mode_endtime: action.payload.home.therm_mode_endtime
      };

      return {
          ...state,
          homes: [...state.homes.filter(h => h.id !== newHome.id), newHome]
      };
    }

    case EnumHomesActions.SetCoolingModeSuccess: {
      const home = state.homes.find(h => h.id === action.payload.home.id);

      const newHome = {
          ...home,
          temperature_control_mode: action.payload.home.temperature_control_mode,
          cooling_mode: action.payload.home.cooling_mode,
          cooling_mode_endtime: action.payload.home.cooling_mode_endtime
      };

      return {
          ...state,
          homes: [...state.homes.filter(h => h.id !== newHome.id), newHome]
      };
    }

    case EnumHomesActions.SetAutoModeSuccess: {
      const home = state.homes.find(h => h.id === action.payload.home.id);

      const newHome = {
          ...home,
          temperature_control_mode: action.payload.home.temperature_control_mode,
          auto_temp_mode: action.payload.home.auto_temp_mode,
          auto_mode_endtime: action.payload.home.auto_mode_endtime
      };

      return {
          ...state,
          homes: [...state.homes.filter(h => h.id !== newHome.id), newHome]
      };
    }

    default:
      return state;
  }
}
