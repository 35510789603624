import { collectionToEntitiesUpdate, getUpdatedCollection } from '../store.functions';
import { EnumModulesActions, ModulesActions } from './modules.action';
import { ApplianceType, ConsumptionType, Module, SourceType } from './modules.interface';
import { isPowerLineEcometer, isPowerLineEnergyMeter } from './modules.selector';
import { ModulesState, initialModulesState } from './modules.state';

const lights = ['NLPT', 'NLL', 'NLF', 'NLFN', 'NLM', 'NLUF', 'NLUO', 'NLUI', 'Z3L', 'NLFE', 'NLIS', 'NLFO'];
const others = ['NLP', 'NLPM', 'NLC', 'NLUP', 'NLPO', 'NLPD'];
const fans = ['NLH'];
const airConditioners = ['NLLF'];
const ecometers = ['NLE'];
const threePhasesMeters = ['NLY'];
const garageDoors = ['NLJ'];

export function ModulesReducers(state = initialModulesState, action: ModulesActions): ModulesState {
  switch (action.type) {
    case EnumModulesActions.GetModulesSuccess: {

      const mapModules: Map<string, Module> = new Map(state.all.map(module => {
        return [module.id, module];
      }));
      action.payload.forEach(home => {
        if (home.modules) {

          home.modules.forEach(module => {
            if (mapModules.has(module.id)) {
              if (module.type === 'NATherm1' || module.type === 'NRV' || module.type === 'BNS' || module.type === 'OTM' || module.type === 'NAThermVaillant') {
                module = {...module, canControlTemperature: true};
              } else {
                module = {...module, canControlTemperature: false};
              }

              mapModules.set(module.id, {
                ...mapModules.get(module.id),
                ...module,
                homeId: home.id,
                roomName: home.rooms?.find(room => room.id === module.room_id)?.name ?? null
              })

            } else {

              mapModules.set(module.id, {
                ...module,
                homeId: home.id,
                roomName: home.rooms?.find(room => room.id === module.room_id)?.name ?? null
              })
            }
          })
        }
      })

      /**
       * Here we handle the friend and favorites modules
       * We create assign them to the fake home which id is the bridge id
       * we also assign them to a room following this rule
       *  - if friend => we use the module id so that all devices are in a separated fake room
       *  - if favorite => we use the module's bridge so that all devices are in the same fake room
       */
      if (action.modules) {
        action.modules.forEach(module => {
          if (mapModules.has(module.id)) {
            mapModules.set(module.id, {
              ...mapModules.get(module.id),
              ...module as Module,
            })
          }
          else {
            mapModules.set(module.id, {
              ...module as Module,
              homeId: module.bridge ?? module.id,
              room_id: (module.mode === 'friend' ? module.id : module.bridge) ?? module.id,
            });
          }
        });
      }

      let updatedModules = [...mapModules.values()];

      // add information needed for non physical modules
      const NLYs = updatedModules.filter(module =>
        module.type === 'NLY' && !module.id.includes('#')
      )

      updatedModules = updatedModules
        .map(module => {
          // Remove NLIS modules without '#' in their ID cause it does not
          // have measures and is not a physical module.
          if (module.type === 'NLIS' && !module.id.includes('#')) {
            return module;
          }

          // Add default appliance types to modules
          if (lights.includes(module.type) && !module.appliance_type) {
              module.appliance_type = ApplianceType.LIGHT;
          } else if (others.includes(module.type) && !module.appliance_type) {
              module.appliance_type = ApplianceType.OTHER;
          } else if (airConditioners.includes(module.type) && !module.appliance_type) {
              module.appliance_type = ApplianceType.AIR_CONDITIONER;
          } else if (fans.includes(module.type) && !module.appliance_type) {
              module.appliance_type = ApplianceType.FAN;
          } else if (module.appliance_type === 'oven') {
            module.appliance_type = ApplianceType.COOKING;
          } else if (garageDoors.includes(module.type) && !module.appliance_type) {
            module.appliance_type = ApplianceType.GARAGE_DOOR;
          }

          if (ecometers.includes(module.type) && module.bridge) {
            if (module.consumption_type === ConsumptionType.ELECTRICAL && !module.source_type) {
              module.source_type = SourceType.OTHER;
            }

            if (module.consumption_type === ConsumptionType.FLUID) {
              if      (module.id.includes('#6')) module.source_type = SourceType.GAS;
              else if (module.id.includes('#7')) module.source_type = SourceType.HOT_WATER;
              else if (module.id.includes('#8')) module.source_type = SourceType.COLD_WATER;
            }

            // Change oven to cooktop
            if (module.source_type === SourceType.OVEN) {
              module.source_type = SourceType.COOKTOP;
            }
          }

          if (threePhasesMeters.includes(module.type)) {
            if (!module.source_type) {
              module.source_type = SourceType.OTHER;
            }
          }

          if (module.appliance_type) {
            // Electrical appliances
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (isPowerLineEcometer(module) || isPowerLineEnergyMeter(module)) {
            // Electrical Powerlines
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (module.type === 'NLG') {
            // Gateway
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (module.type === 'NLE' && !module.bridge) {
            // Total Ecometer
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (module.type === 'NLPC') {
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (module.type === 'NLPS') {
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
          } else if (module.type === 'NLY') {
            module = {...module, consumption_type: ConsumptionType.ELECTRICAL};
            if (module.id.includes('#')) {
              const NLY =  NLYs.find(nly => module.id.includes(nly.id))
              if (NLY) {
                module.room_id = NLY.room_id
              }
            }
          }

          return module;
        });

      return {
        ...state,
        all: updatedModules
      };

    }


    case EnumModulesActions.ChangePolaritySuccess: {
      return {
        ...state,
        all: state.all.map((mod) => {
            const module = {...mod};
            if (module.id === action.payload.device_id) {
              // behavior is a binary number and to change it we have to do bitwise operation.
              // The polarity is at the sixth index in the binary number so we use the number 64 (2^6)
              if (parseInt(action.payload.relay_inv_en, 10) === 0) {
                module.behavior = module.behavior & (~64);
              } else if (parseInt(action.payload.relay_inv_en, 10)=== 1) {
                module.behavior = module.behavior | 64;
              }
            }
            return module;
        })
      };
  }

    case EnumModulesActions.GetModulesFailure: {
      console.error('Failure', action);
      return {
        ...state,
      };
    }

    case EnumModulesActions.SetModulesErrors: {
      const updatedModules = state.all.map(module => {
        const error = action.payload.errors.find(e => e.id === module.id);

        if (error) {
          module = {
            ...module,
            error,
          };
        } else{
          if(module.error){
            delete module.error
          }
        }

        return module;
      });

      return {
        ...state,
        all: updatedModules
      };
    }

    case EnumModulesActions.SetModulesStatusErrors: {
      let updatedModules = state.all.map(module => {
        const error = action.payload.errors.find(e => e.id === module.id);

        if (error) {
          module = {
            ...module,
            error,
            status_loaded: true,
            reachable: false,
          };
        } else{
          if(module.error){
            delete module.error
          }
        }

        return module;
      });
      action.payload.errors.forEach(error => {
        updatedModules = updatedModules.map(module => {
          if (module.bridge === error.id) {
            return {
              ...module,
              reachable: false,
              status_loaded: true,  
            }
          } else {
            return module;
          }
        })
      })

      return {
        ...state,
        all: updatedModules,
      };
    }
    // add config to modules
    case EnumModulesActions.UpdateModulesGetConfig: {
      const modulesMap: Map<string, Module> = new Map(state.all.map(module => {
        return [module.id, module];
      }));
      if (action.payload.modules) {
        action.payload.modules.forEach(module => {
          if (modulesMap.has(module.id)) {
            const currentConfig = modulesMap.get(module.id).config
            modulesMap.set(module.id, {
              ...modulesMap.get(module.id),
              config: {...currentConfig,...module},
              homeId: action.payload.id
            })
          } else {
            modulesMap.set(module.id, {
              id: module.id,
              config: module,
              homeId: action.payload.id
            } as Module)
          }
        })
      }

      return {
        ...state,
        all: [...modulesMap.values()],
      }
    }

    case EnumModulesActions.UpdateModulesSetConfig: {
      const modules = action.payload.home.modules;
      return {
        ...state,
        all: getUpdatedCollection(
          state.all,
          collectionToEntitiesUpdate(
            modules ? modules.map(newConfig => {
              const oldConfig = state.all.find(module => module.id === newConfig.id).config;
              return {id: newConfig.id, config: {...oldConfig, ...newConfig}} as Module;
            }) : []
          )
        )
      };
    }

    /*
      Merge modules data from homesdata and homestatus
        - the old state is the modules data from homesdata
        - the new state is the old state + modules data from homestatus
        - add status if the module was not in store yet
    */
    case EnumModulesActions.UpdateModulesStatus: {
      const modulesMap: Map<string, Module> = new Map(state.all.map(module => {
        return [module.id, module];
      }));
      if (action.payload.modules) {
        action.payload.modules.forEach(module => {
          if (modulesMap.has(module.id)) {
            modulesMap.set(module.id, {
              ...modulesMap.get(module.id),
              ...module,
              homeId: action.payload.id,
              status_loaded: true
            })
          } else {
            modulesMap.set(module.id, {
              id: module.id,
              ...module,
              homeId: action.payload.id,
              status_loaded: true
            })
          }
          if (module.appliance_type === 'oven') {
            modulesMap.set(module.id, {
              ...modulesMap.get(module.id),
              appliance_type: ApplianceType.COOKING
            })
          }
        })
      }
      return {
        ...state,
        all: [...modulesMap.values()],
      }

    }

    case EnumModulesActions.ModuleSelected: {
      return {
        ...state,
        currentModuleId: action.payload
      };
    }

    case EnumModulesActions.UpdateModuleNameSuccess: {
      const updatedModules = state.all.map((module: Module) => {
          if (module.id === action.payload.module_id) {
              module = {
                  ...module,
                  ...action.payload
              };
          }
          return module;
      });
      return {
        ...state,
        all: updatedModules
      };
    }

    case EnumModulesActions.UpdateModuleNameFailure: {
        return {
          ...state
        };
    }

    case EnumModulesActions.UpdateModuleRoomSuccess: {

      const all = state.all.map( (module: Module) => {
        const id  = action.payload.module_id ? action.payload.module_id : action.payload.device_id;
        if (module.id === id) {
          module.room_id = action.payload.room_id;
        }
        return module;
      });

      return {
        ...state,
        all
      };

    }

    case EnumModulesActions.RemoveModuleFromHomeSuccess: {
      const all = state.all.filter( (module: Module) => {
        return module.id !== action.payload.device_id;
      });

      return {
        ...state,
        all
      };
    }

    case EnumModulesActions.RemoveModuleFromHomeFailure: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
