import { Action } from '@ngrx/store';
import { HomeData } from '../homes/homes.interface';
import { QuizzData, Schedule } from './schedules.interface';

export enum EnumSchedulesActions {
  GetSchedules = '[Schedules] Get Schedules data',
  GetSchedulesSuccess = '[Schedules] Get Schedules data success',
  GetSchedulesFailure = '[Schedules] Get Schedules data failure',
  QuizzDataSet = '[Schedules] Set quizz data',
  CreateNewSchedule = '[Schedules] Create new schedule',
  CreateNewScheduleSuccess = '[Schedules] Create new schedule success',
  CreateNewScheduleFailure = '[Schedules] Create new schedule failure',
  ModifySchedule = '[Schedules] Modify schedule',
  ModifyScheduleSuccess = '[Schedules] Modify schedule success',
  ModifyScheduleFailure = '[Schedules] Modify schedule failure',
  SelectNewSchedule = '[Schedules] Select new schedule',
  SelectNewScheduleSuccess = '[Schedules] Select new schedule success',
  SelectNewScheduleFailure = '[Schedules] Select new schedule failure',
}

export class GetSchedules implements Action {
  public readonly type = EnumSchedulesActions.GetSchedules;
  constructor(public payload: any) { }
}

export class GetSchedulesSuccess implements Action {
  public readonly type = EnumSchedulesActions.GetSchedulesSuccess;
  constructor(public payload: HomeData) { }
}

export class GetSchedulesFailure implements Action {
  public readonly type = EnumSchedulesActions.GetSchedulesFailure;
  constructor(public payload: any) { }
}

export class SetQuizzData implements Action {
  public readonly type = EnumSchedulesActions.QuizzDataSet;
  constructor(public payload: Partial<QuizzData>) { }
}

export class CreateNewSchedule implements Action {
  public readonly type = EnumSchedulesActions.CreateNewSchedule;
  constructor(public payload: any) { }
}

export class CreateNewScheduleSuccess implements Action {
  public readonly type = EnumSchedulesActions.CreateNewScheduleSuccess;
  constructor(public payload: { schedule_id: string, schedule_info: any }) { }
}

export class CreateNewScheduleFailure implements Action {
  public readonly type = EnumSchedulesActions.CreateNewScheduleFailure;
  constructor(public payload: any) { }
}

export class ModifySchedule implements Action {
  public readonly type = EnumSchedulesActions.ModifySchedule;
  constructor(public payload: Schedule) { }
}

export class ModifyScheduleSuccess implements Action {
  public readonly type = EnumSchedulesActions.ModifyScheduleSuccess;
  constructor(public payload: Schedule) { }
}

export class ModifyScheduleFailure implements Action {
  public readonly type = EnumSchedulesActions.ModifyScheduleFailure;
  constructor(public payload: any) { }
}

export class SelectNewSchedule implements Action {
  public readonly type = EnumSchedulesActions.SelectNewSchedule;
  constructor(public payload: { home_id: string, schedule_id: string }) { }
}

export class SelectNewScheduleSuccess implements Action {
  public readonly type = EnumSchedulesActions.SelectNewScheduleSuccess;
  constructor(public payload: string) { }
}

export class SelectNewScheduleFailure implements Action {
  public readonly type = EnumSchedulesActions.SelectNewScheduleFailure;
  constructor(public payload: any) { }
}

export type SchedulesActions = GetSchedules
  | GetSchedulesSuccess
  | GetSchedulesFailure
  | SetQuizzData
  | CreateNewSchedule
  | CreateNewScheduleSuccess
  | CreateNewScheduleFailure
  | ModifySchedule
  | ModifyScheduleSuccess
  | ModifyScheduleFailure
  | SelectNewSchedule
  | SelectNewScheduleSuccess
  | SelectNewScheduleFailure
  ;
